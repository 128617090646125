import { mapState } from 'vuex'

import {
  fetchProductByUID_ClientAll,
  fetchProductByUID_ClientShort,
} from '@/prismic/queries'

import { fetchProductByHandle } from '@/shopify/products'

import { getTitle, getShopifyProduct } from '@/helpers'

export default {
  data() {
    return {
      isShoppingEnabled: false,
      shopCategory: null,
      productData: {},
      shopifyProductMore: null,
      selections: {
        size: {
          selected: null,
        },
        shade: {
          selected: null,
          hovered: null,
        },
        frequency: {
          selected: 'once',
          subscriptionFrequency: null,
        },
        quantity: {
          selected: 1,
        },
      },
      isBestMatch: false, // To Do when quiz is ready
    }
  },

  computed: {
    ...mapState({
      locale: state => state.locale,
    }),

    shopifyProduct() {
      return getShopifyProduct(this.product, this.locale)
    },

    shopifyBundleProducts() {
      if (!this.isBundle || !this.product || !this.product.bundle_products)
        return null

      return this.product.bundle_products.map(product => {
        return getShopifyProduct(product.product, this.locale)
      })
    },

    hasIcons() {
      if (!this.product) return null

      return this.productData.is_am || this.productData.is_pm
    },

    isBestSeller() {
      return this.product && this.product.is_best_seller
    },

    isJustIn() {
      if (!this.shopifyProduct) return null

      // If the product is newer than 7 days ago
      const sevenDaysAgo = new Date().setDate(new Date().getDate() - 7)

      return Date.parse(this.shopifyProduct.published_at) > sevenDaysAgo
    },

    isSoldOut() {
      return (
        this.shopifyProductMore &&
        this.shopifyProductMore.availableForSale == false
      )
    },

    isUnavailable() {
      return this.locale == 'en-ca'
        ? this.product.is_unavailable_ca
        : this.product.is_unavailable_us
    },

    isVariantSoldOut() {
      if (this.hasVariants) {
        return !this.isVariantAvailable(
          this.selections.shade.selected,
          this.selections.size.selected
        )
      }
    },

    isSelectedVariantComingSoon() {
      if (this.hasVariants) {
        return this.isVariantComingSoon(
          this.selections.shade.selected,
          this.selections.size.selected
        )
      }
    },

    isPreOrder() {
      if (!this.product) return null

      return this.product.is_pre_order
    },

    tagText() {
      if (this.product && this.product.tag_override) {
        return this.product.tag_override
      }

      if (this.isSoldOut && !this.addToBagOverrideLink) {
        return 'Sold Out'
      }

      if (this.isBestMatch) {
        return 'Best Match'
      }

      if (this.isBestSeller) {
        return 'Best Seller'
      }

      if (this.isJustIn) {
        return 'Just In'
      }
    },

    isSkincare() {
      return this.shopCategory && this.shopCategory.type === 'skincare'
    },

    isKit() {
      return this.product && this.product.is_kit
    },

    isBundle() {
      return this.product && this.product.is_bundle
    },

    bundleDiscount() {
      if (!this.defaultPrice && this.defaultSalePrice) return null

      return Math.round(
        ((this.defaultPrice - this.defaultSalePrice) / this.defaultPrice) * 100
      )
    },

    frequencies(){
      const options = {}
      this.shopifyProductMore.variants[0].sellingPlanAllocations.edges.forEach(function (plan, i) {
        options[plan.node.sellingPlan.name] = plan.node.sellingPlan.name;
      })
      return options
    },

    addToBagOverrideLink() {
      return this.locale == 'en-ca'
        ? this.product.add_to_bag_override_link_ca
        : this.product.add_to_bag_override_link_us
    },

    addToBagOverrideText() {
      return this.locale == 'en-ca'
        ? this.product.add_to_bag_override_text_ca
        : this.product.add_to_bag_override_text_us
    },

    variants() {
      return this.shopifyProductMore && this.shopifyProductMore.variants
    },

    hasVariants() {
      return (
        this.variants &&
        this.variants[0] &&
        this.variants[0].title !== 'Default Title'
      )
    },

    localizedSpecificVariantSKU() {
      return this.locale == 'en-ca'
        ? this.product.canadian_variant_associated_sku
        : this.product.us_variant_associated_sku
    },

    specificVariantProduct() {
      if (!this.hasVariants) return false

      if (this.product.explodeVariants) return this.product

      return this.variants.find(variant => {
        return variant.sku == this.localizedSpecificVariantSKU
      })
    },

    specificVariantProductTitle() {
      if (!this.specificVariantProduct) return null

      if (this.product.explodeVariants) return this.product.variantTitle

      return this.specificVariantProduct.title
    },

    specificVariantProductId() {
      if (!this.specificVariantProduct) return null

      if (this.product.explodeVariants) return this.product.variantId

      return this.cleanVariantId(this.specificVariantProduct.id)
    },

    specificVariantProductLink() {
      if (!this.specificVariantProduct) return null

      return `/shop/product/${this.product._meta.uid}?variant=${this.specificVariantProductId}`
    },

    visibleVariants() {
      if (!this.product || !this.variants) return null

      if (this.isProductInBundle) {
        const onlyVisibleSKUs =
          this.locale == 'en-ca'
            ? this.product.only_visible_variant_skus_ca
            : this.product.only_visible_variant_skus_us

        if (onlyVisibleSKUs) {
          return this.variants.filter(variant => {
            return onlyVisibleSKUs.includes(variant.sku)
          })
        } else {
          return this.variants
        }
      } else {
        const onlyHiddenSKUs =
          this.locale == 'en-ca'
            ? this.product.unavailable_variant_skus_ca
            : this.product.unavailable_variant_skus_us

        if (onlyHiddenSKUs) {
          return this.variants.filter(variant => {
            return !onlyHiddenSKUs.includes(variant.sku)
          })
        } else {
          return this.variants
        }
      }
    },

    comingSoonVariants() {
      if (!this.product || !this.variants) return null

      const onlyComingSoonSKUs =
        this.locale == 'en-ca'
          ? this.product.coming_soon_variant_skus_ca
          : this.product.coming_soon_variant_skus_us

      if (onlyComingSoonSKUs) {
        return this.variants.filter(variant => {
          return onlyComingSoonSKUs.includes(variant.sku)
        })
      } else {
        return []
      }
    },

    isOnSale() {
      if (!this.shopifyProduct) return null

      if (this.isBundle) return true

      if (this.variants) {
        const price =
          (this.specificVariantProduct &&
            this.specificVariantProduct.compareAtPrice?.amount) ||
          this.variants[0].compareAtPrice?.amount
        const salePrice =
          (this.specificVariantProduct && this.specificVariantProduct.price?.amount) ||
          this.variants[0].price?.amount

        return (
          price &&
          price !== '0' &&
          price !== '0.0' &&
          price !== '0.00' &&
          price !== salePrice
        )
      }
    },

    bundleProductsTotalPrice() {
      if (!this.isBundle && !this.shopifyBundleProducts) return null

      return this.shopifyBundleProducts.reduce((acc, cur) => {
        return acc + parseFloat(cur?.variants[0].price?.amount)
      }, 0)
    },

    defaultPrice() {
      if (!this.shopifyProduct) return null

      const type = this.isOnSale ? 'compareAtPrice' : 'price'

      let price
      if (this.variants) {
        price = this.specificVariantProduct
          ? this.specificVariantProduct[type]?.amount
          : this.variants[0][type]?.amount
      } else {
        price = this.shopifyProduct.variants[0][type]?.amount
      }

      if (this.product.explodeVariants) return this.product.price?.amount

      if (this.isBundle && this.shopifyBundleProducts) {
        return price || this.bundleProductsTotalPrice
      } else {
        return price
      }
    },

    defaultSalePrice() {
      if (this.shopifyProduct && this.isOnSale) {
        if (this.variants) {
          return this.specificVariantProduct
            ? this.specificVariantProduct.price?.amount
            : this.variants[0].price?.amount
        }

        return this.shopifyProduct.variants[0].price?.amount
      }
    },

    lowestPrice() {
      if (this.shopifyProduct) {
        const variants = this.variants
          ? this.variants
          : this.shopifyProduct.variants

        const hasVariablePrices = variants.some((variant, index) => {
          if (index > 0) {
            return variant.price?.amount !== variants[index - 1].price?.amount
          }
        })

        if (hasVariablePrices) {
          return variants.sort((a, b) => {
            if (a.price?.amount && b.price?.amount) {
              if (a.price?.amount < b.price?.amount) {
                return -1
              }
              if (a.price?.amount > b.price?.amount) {
                return 1
              }
              return 0
            } else {
              return -1
            }
          })[0].price?.amount
        }
      }
    },

    featuredImage() {
      if (this.shopifyProduct) {
        if (this.localizedSpecificVariantSKU) {
          return this.specificVariantProduct.image;
        }

        return this.specificVariantProduct
          ? this.specificVariantProduct.image
          : this.shopifyProductMore && this.shopifyProductMore?.images
          ? this.shopifyProductMore.images[0]
          : this.shopifyProduct.image
      }
    },

    shades() {
      if (this.variants) {
        var shadeOptions = this.shopifyProductMore.options.find(
          option =>
            option.name == 'Colour' ||
            option.name == 'Color' ||
            option.name == 'Shade' ||
            option.name == 'Kits'
        )
        var allShadeNames = shadeOptions ? shadeOptions.values : null

        if (!allShadeNames) return []

        return allShadeNames.map(shadeName => {
          const isAvailable = this.isVariantAvailable(
            shadeName,
            this.selections.size.selected
          )

          const isVisible = this.isVariantVisible(
            shadeName,
            this.selections.size.selected
          )

          const isComingSoon = this.isVariantComingSoon(
            shadeName,
            this.selections.size.selected
          )

          const shadeVariant = this.variants.find(variant => {
            return variant.title.split(' / ')[0] == shadeName
          })

          return {
            name: shadeName,
            image: shadeVariant ? shadeVariant.image : null,
            sku: shadeVariant ? shadeVariant.sku : null,
            hexCode: shadeVariant
              ? shadeVariant.hexCode && shadeVariant.hexCode.value
              : null,
            available: isAvailable,
            visible: isVisible,
            comingSoon: isComingSoon
          }
        })
      }
    },

    sizes() {
      if (this.variants) {
        var sizeOptions = this.shopifyProductMore.options.find(option => {
          return (
            option.name == 'Size' ||
            option.name == 'Concentrations' ||
            option.name == 'Denominations' ||
            option.name == 'Scent' ||
            option.name == 'Time'
          )
        })
        var allSizeNames = sizeOptions ? sizeOptions.values : null

        if (!allSizeNames) return []

        return allSizeNames.map(sizeName => {
          const isAvailable = this.isVariantAvailable(
            this.selections.shade.selected,
            sizeName
          )

          const isVisible = this.isVariantVisible(
            this.selections.shade.selected,
            sizeName
          )

          const isComingSoon = this.isVariantComingSoon(
            this.selections.shade.selected,
            sizeName
          )

          return {
            name: sizeName,
            available: isAvailable,
            visible: isVisible,
            comingSoon: isComingSoon
          }
        })
      }
    },


    sellingPlans() {

      console.log(this.shopifyProductMore)

      if (this.variants) {


        console.log("SAd", this.shopifyProductMore)

        return this.shopifyProductMore.variants.map(sizeName => {
          const isAvailable = this.isVariantAvailable(
            this.selections.shade.selected,
            sizeName
          )

          const isVisible = this.isVariantVisible(
            this.selections.shade.selected,
            sizeName
          )

          return {
            description: sizeName,
            available: isAvailable,
            visible: isVisible,
          }
        })

        console.log(this.shopifyProductMore.variants)

        /*
        var sizeOptions = this.shopifyProductMore.options.find(option => {
          return (
            option.name == 'Size' ||
            option.name == 'Concentrations' ||
            option.name == 'Denominations'
          )
        })
        var allSizeNames = sizeOptions ? sizeOptions.values : null

        if (!allSizeNames) return []

        return allSizeNames.map(sizeName => {
          const isAvailable = this.isVariantAvailable(
            this.selections.shade.selected,
            sizeName
          )

          const isVisible = this.isVariantVisible(
            this.selections.shade.selected,
            sizeName
          )

          return {
            name: sizeName,
            available: isAvailable,
            visible: isVisible,
          }
        })
        */
      }
    },

    sizeLabel() {
      if (this.sizes && this.sizes.length) {
        const option = this.shopifyProductMore.options.find(option => {
          return (
            option.name == 'Size' ||
            option.name == 'Concentrations' ||
            option.name == 'Denominations' ||
            option.name == 'Scent' ||
            option.name == 'Time'
          )
        })

        return option?.name
      }
    },

    selectedVariantTitle() {
      if (this.variants) {
        const selectedShadeName = this.selections.shade.selected
        const selectedSizeName = this.selections.size.selected

        if (selectedShadeName && selectedSizeName) {
          return selectedShadeName + ' / ' + selectedSizeName
        }

        if (selectedShadeName) {
          return selectedShadeName
        }

        if (selectedSizeName) {
          return selectedSizeName
        }
      }
    },

    selectedVariant() {
      if (this.shopifyProductMore) {
        if (this.selectedVariantTitle) {
          return this.variants.find(
            variant => variant.title == this.selectedVariantTitle
          )
        }

        return this.variants[0]
      }
    },

    selectedVariantId() {
      if (this.selectedVariant) {
        return this.selectedVariant
          ? this.selectedVariant.id
          : this.variants[0].id
      }
    },

    selectedVariantSKU() {
      if (this.selectedVariant) {
        return this.selectedVariant
          ? this.selectedVariant.sku
          : this.variants[0].sku
      }
    },

    prices() {

      const oneTimePrice = this.selectedVariant
        ? this.isOnSale
          ? this.selectedVariant.compareAtPrice?.amount
          : this.selectedVariant.price?.amount
        : this.defaultPrice

      var subprice = 0;
      if(this.selectedVariant) {
        if(this.selectedVariant.sellingPlanAllocations.edges.length > 0) {
          //subprice = oneTimePrice;
          subprice = this.selectedVariant.sellingPlanAllocations.edges[0].node.priceAdjustments[0].price?.amount;
        }
      }

      return {
        once: oneTimePrice,
        subscription: subprice,
      }
    },

    salePrices() {

      if (!this.isOnSale) {
        return {
          once: null,
          subscription: null,
        }
      }

      if (this.isOnSale) {
        const oneTimePrice = this.selectedVariant
          ? this.selectedVariant.price?.amount
          : this.defaultSalePrice

        return {
          once: this.selectedVariant
            ? this.selectedVariant.price?.amount
            : this.defaultSalePrice,
          subscription: oneTimePrice,
        }
      }
    },

    selectedProduct() {
      if (this.selectedVariant) {
        if (this.isGiftCard) {
          this.product._meta.uid = 'gift-card'
        }

        const selectedProduct = {
          title: this.isGiftCard ? 'E-Gift Card' : getTitle(this.product.title),
          _meta: this.product._meta,
          id: this.shopifyProduct.id,
          image: this.selectedVariant.image,
          product_type: this.product.product_type,
          variantTitle: this.selectedVariantTitle,
          variantId: this.selectedVariantId,
          variantIdQuery: this.variantIdQueryParam,
          brand: !this.isGiftCard && this.product.brand,
          price: this.prices[this.selections.frequency.selected],
          salePrice: this.salePrices[this.selections.frequency.selected],
          quantity: this.selections.quantity.selected,
          shade: this.selections.shade.selected,
          size: this.selections.size.selected,
          frequency: this.selections.frequency.selected,
          subscriptionFrequency: this.selections.frequency.subscriptionFrequency,
          isSoldOut: this.isSoldOut || this.isVariantSoldOut,
          isComingSoon: this.isSelectedVariantComingSoon,
          handle: this.shopifyProduct.handle
        }

        if (this.isProductInBundle) {
          selectedProduct['bundleIndex'] = this.index
        }

        return selectedProduct;
      }
    },
  },

  methods: {
    cleanVariantId(variantId) {
      if (!variantId) return null;

      return variantId.replace('gid://shopify/ProductVariant/', '');
    },

    async fetchProductData(uid) {
      try {
        const { data } = await this.$apolloProvider.defaultClient.query({
          query: this.isSingle
            ? fetchProductByUID_ClientAll
            : fetchProductByUID_ClientShort,
          variables: { uid: uid },
        })

        if (!data) return null

        this.productData = data.product

        if (this.isSingle) {
          const globalProductData = data.allGlobals.edges[0].node

          this.$set(
            this.productData,
            'manual_related_products',
            this.productData.related_products
          )
          this.$set(
            this.productData,
            'related_products_title',
            this.productData.related_products_title
          )
          this.$set(
            this.productData,
            'related_products_subtitle',
            this.productData.related_products_subtitle
          )
          this.$set(
            this.productData,
            'related_products_description',
            this.productData.related_products_description
          )

          this.$set(
            this.productData,
            'global_related_products_title',
            globalProductData.related_products_title
          )
          this.$set(
            this.productData,
            'global_related_products_subtitle',
            globalProductData.related_products_subtitle
          )
          this.$set(
            this.productData,
            'global_related_products_description',
            globalProductData.related_products_description
          )
        }
      } catch (err) {
        console.log(err)
        return this.$nuxt.error(err)
      }
    },

    async fetchAdditionalShopifyProductInfo() {
      if (this.shopifyProduct) {
        try {
          const { data } = await this.$shopify.client.query({
            query: fetchProductByHandle,
            variables: { handle: this.shopifyProduct.handle },
            fetchPolicy: 'no-cache',
          })

          const { productByHandle } = data

          if (!productByHandle) return null

          this.shopifyProductMore = {
            availableForSale: productByHandle.availableForSale,
            variants: productByHandle.variants.edges.map(({ node }) => node),
            options: productByHandle.options,
            images: productByHandle.images.edges.map(({ node }) => node),
          }
        } catch (err) {
          console.log(err)
          return this.$nuxt.error(err)
        }
      }
    },

    findUIDInShopMenu(uid, arr, key) {
      if (!arr) return null

      // If element has the UID, lets return some info about the element.
      const arrayHasUid = arr.filter(ele => {
        const hasUID = ele[key].filter(
          ({ _meta }) => _meta !== null && _meta.uid === uid
        )

        if (!hasUID.length) return null

        return {
          title: ele.title,
          _meta: ele._meta,
        }
      })

      if (!arrayHasUid.length) return null

      return arrayHasUid[0]
    },

    fetchShopCategory() {
      if (!this.productData.product_type) return null

      const { uid } = this.productData.product_type._meta
      const baseArray = this.$store.state.header.links.shop

      this.shopCategory = this.findUIDInShopMenu(uid, baseArray, 'productTypes')
    },

    setDefaultSelections() {
      if (!this.variants) return null

      let defaultVariant
      if (this.variantIdQueryParam || this.quickShopVariantId) {
        // Finds variant based on variant id query param
        defaultVariant = this.variants.find(variant => {
          return (
            this.cleanVariantId(variant.id) ==
            (this.variantIdQueryParam || this.quickShopVariantId)
          )
        })
      } else {
        // Finds first available variant or first variant if non available
        const firstAvailableVariant = this.visibleVariants.find(
          variant => variant.availableForSale
        )

        defaultVariant = firstAvailableVariant
          ? firstAvailableVariant
          : this.visibleVariants[0]
      }

      if (defaultVariant) {
        const splitVariant = defaultVariant.title.split(' / ')

        if (
          this.shades &&
          this.shades.length &&
          this.sizes &&
          this.sizes.length
        ) {
          this.selections.shade.selected = splitVariant[0]
          this.selections.size.selected = splitVariant[1]
        } else {
          if (this.shades.length) {
            this.selections.shade.selected = splitVariant[0]
          }

          if (this.sizes.length) {
            this.selections.size.selected = splitVariant[0]
          }
        }
      }
    },

    findVariantBySelections(shade, size) {
      if (this.variants) {
        return this.variants.find(variant => {
          if (shade && size) return variant.title == shade + ' / ' + size

          if (shade) return variant.title == shade

          if (size) return variant.title == size

          return true
        })
      }
    },

    isVariantAvailable(shade, size) {
      if (this.variants) {
        const currentVariant = this.findVariantBySelections(shade, size)

        return currentVariant && currentVariant.availableForSale
      }
    },

    isVariantVisible(shade, size) {
      if (this.variants) {
        const currentVariant = this.findVariantBySelections(shade, size)

        return (
          this.visibleVariants && this.visibleVariants.includes(currentVariant)
        )
      }
    },

    isVariantComingSoon(shade, size) {
      if (this.variants) {
        const currentVariant = this.findVariantBySelections(shade, size)

        return (
          this.comingSoonVariants && this.comingSoonVariants.includes(currentVariant)
        )
      }
    },

    async initShopping() {
      await this.fetchAdditionalShopifyProductInfo()
      this.setDefaultSelections()
      this.isShoppingEnabled = true
    },

    handleTracking() {
      this.handleKlaviyoTracking()
      this.handleGoogleAnalyticsTracking()
    },

    handleKlaviyoTracking() {
      if (this.$store.state.user.data?.email !== null) return null

      this.$klaviyo.trackProductImpression({
        Name: getTitle(this.product.title),
        Brand: this.product.brand.title
          ? getTitle(this.product.brand.title)
          : 'Gee Beauty',
        Categories: [getTitle(this.shopCategory.title)],
        ProductID: this.shopifyProduct.id,
        ImageURL: this.selectedVariant.image,
        URL: window.location.href,
        Price: this.prices[this.selections.frequency.selected],
        // CompareAtPrice: this.selectedVariant.compare_at_price
      })
    },

    handleGoogleAnalyticsTracking() {
      this.$gtag.event('page_view', {
        send_to: this.$config[this.$store.state.locale].tracking.adWords,
        ecomm_prodid: this.shopifyProduct.id, // must be mapped from product data
        ecomm_pagetype: 'product', // this could be fixed as 'product' instead of dynamic
        ecomm_totalvalue: this.prices[this.selections.frequency.selected], // must be mapped from product data
      })

      this.$gtag.event('view_item', {
        items: [
          {
            id: this.product.id,
            name: getTitle(this.product.title),
            variant:
              getTitle(this.product.variantTitle) ??
              getTitle(this.product.title),
            brand: this.product.brand
              ? getTitle(this.product.brand.title)
              : 'Gee Beauty',
            category:
              this.product.product_type &&
              getTitle(this.product.product_type.title),
          },
        ],
      })
    },
  },
}
